import { Header } from "../Header";
import { Footer } from "../Footer";
import classes from "./Layout.module.css";
import classNames from "classnames";
import { Outlet } from "react-router-dom";
import { useInitNetwork } from "../Network/context/Network/useInitNetwork";
import { ChangeNetworkModal, Modal } from "@curiodao/capital-dex-ui-kit";
import { NetworkService, useNetworkContext } from "../Network";
import { useState } from "react";
import { useWeb3ModalProvider } from "@web3modal/ethers/react";

export const Layout = () => {
  const { errorNetwork } = useInitNetwork();
  const { network } = useNetworkContext();
  const [networkService] = useState(() => new NetworkService());
  const { walletProvider } = useWeb3ModalProvider();

  const switchNetwork = async () => {
    if (walletProvider) {
      networkService.switchNetwork(network, walletProvider);
    }
  };

  return (
    <div className={classNames("bg", classes.Layout)}>
      <Header />
      <main className="container">
        <Outlet />
      </main>
      <Footer />
      {errorNetwork && (
        <Modal title="Network error">
          <ChangeNetworkModal
            networkName={errorNetwork}
            tokenSymbol={""}
            switchNetwork={() => switchNetwork()}
          />
        </Modal>
      )}
    </div>
  );
};
