import classNames from "classnames";
import classes from "./Menu.module.css";
import { MENU } from "../../constants/menu";
import { getMenu } from "../../mappers/getMenu";
import { NavLink } from "react-router-dom";
import { INetworkCore } from "../../../../common/_evm/interfaces/INetworkCore";
import { useNetworkContext } from "../../../Network";

export const Menu = ({
  showMenu,
  core,
  isParachain,
  isTon,
  closeModal,
}: {
  showMenu: boolean;
  core: INetworkCore | undefined;
  isParachain: boolean;
  isTon: boolean;
  closeModal?: () => void;
}) => {
  const { network } = useNetworkContext();
  return (
    <div
      className={classNames(
        classes.NavigationBlock,
        showMenu ? classes.ShowMenu : "",
      )}
    >
      <ul className={classes.NavigationList}>
        {getMenu(MENU, core, isParachain, isTon).map((item) => (
          <li key={item.title}>
            <NavLink
              to={item.link}
              className={`menu-item`}
              end={item.link === "/"}
              onClick={closeModal}
            >
              {item.title}
            </NavLink>
          </li>
        ))}
        <li key="Bridge">
          <a
            className={`menu-item`}
            target="_blank"
            href={
              network === "skale"
                ? "https://portal.skale.space/bridge?from=mainnet&to=elated-tan-skat&token=cgt&type=erc20"
                : "https://bridge.capitaldex.exchange/"
            }
            rel="noreferrer"
          >
            Bridge
          </a>
        </li>
        {network === "skale" && (
          <li key="Buy">
            <a
              className="menu-item"
              href="https://portal.skale.space/onramp"
              target="_blank"
              rel="noreferrer"
            >
              Buy
            </a>
          </li>
        )}
      </ul>
    </div>
  );
};
